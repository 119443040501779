import {Button, DatePicker, message, FloatButton, ConfigProvider} from "antd";
import 'antd/dist/reset.css';
import {useState} from "react";

function App() {
    const [date, setDate] = useState(null);
    const handleChange = (value) => {
        message.info(`Selected Date: ${value ? value.format('YYYY-MM-DD') : 'None'}`);
        setDate(value);
    }
    return (<>
            <Button type="primary">PRESS ME!</Button>
            <DatePicker placeholder="select date" onChange={handleChange}/>
            <FloatButton onClick={() => console.log('click')}/>
        </>
    );
}

export default App;